:global {
  .export-list {
    .row {
      height: 45px;
      display: flex;
      flex-direction: row;
      font-size: 12px;
      color: #253740;

      &.export {
        .pticon-offline_playericon {
          font-size: 25px;
        }

        .pticon-mp4icon {
          font-size: 19px;
        }

        .pticon-ppticon {
          font-size: 25px;
        }

        .pticon-pdficon {
          font-size: 27px;
        }

        .pticon-slideshareicon {
          font-size: 26px;
        }

        .pticon-hubspoticon {
          font-size: 27px;
        }
      }

      a {
        color: #42a5f5;
        text-decoration: underline;
      }

      div {
        align-self: center;
        color: #424f61;
      }
    }

    .upto-date {
      color: green;
    }

    .out-dated {
      color: red;
    }

    .heading {
      font-family: Muller-Regular, sans-serif;
      font-size: 12px;
      border-bottom: dashed 1px #a0aab7;
      margin-bottom: 17px;
      height: auto;
      padding-bottom: 8px;

      div {
        color: #253740;
        font-family: Muller-Regular, sans-serif;
      }

      .type {
        padding-left: 6px;
      }
    }

    .export {
      .upgrade-tooltip-wrapper .manageexports .tooltipclose {
        font-size: 9px;
        top: 6px;
        color: #aaa;
        right: 7px;
      }

      .icon {
        font-size: 21px;
        width: 43px;
        display: flex;
        justify-content: center;
        color: #424f61;
      }
    }
  }
}
