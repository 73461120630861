:global {
  /* stylelint-disable no-descending-specificity */
  .tab-content {
    overflow: initial !important;
  }

  .upgrade-tooltip-wrapper {
    position: relative;

    .manageexports {
      bottom: 25px;
      right: 20px;
      border-radius: 10px;
      padding: 18px 13px 0 13px;
      width: 280px;
      line-height: 1.4;
      opacity: 1;
      font-size: 13px;
      text-align: left;
      color: #333;
      font-family: Muller-Light, 'Ubuntu', sans-serif;
      box-sizing: border-box;
      box-shadow: 2px 1px 6px rgba(0, 0, 0, 0.28);
      background: white;
      border: 1px solid #adadad;
      position: absolute;
      z-index: 1020;

      .tooltipclose {
        right: 5px;
        top: 0;
        position: absolute;
        text-decoration: none;
        font-style: normal;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
      }

      .upgradebutton {
        line-height: 31px !important;
        font-size: 14px;
        height: 34px;
        padding: 0 7px;
        width: 50%;
        display: block;
        margin: 11px auto;
        background-color: #f71851;
        cursor: pointer;
        text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
        box-shadow: inset 0 -2px #f71851;
        text-align: center;
        color: white;
        text-decoration: none;
        border-radius: 4px;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        vertical-align: middle;
        box-sizing: border-box;

        &:hover,
        &:focus {
          background-position: 0 -15px;
          transition: background-position 0.1s linear;
          background-color: #d50a3d;
        }
      }

      // before and after are tooltip bottom arrow
      &:before,
      &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #fff;
        border-width: 13px;
        margin-left: -13px;
      }

      &:before {
        border-top-color: #adadad;
        border-width: 15px;
        margin-left: -15px;
      }
    }

    .publishtargets {
      bottom: initial;
      right: -102px;
      top: 65px;
      border-radius: 5px;
      padding: 15px;
      width: 185px;
      line-height: 1.4;
      opacity: 1;
      font-size: 13px;
      text-align: left;
      color: #424f61;
      box-sizing: border-box;
      background: #fff;
      position: absolute;
      z-index: 1020;
      filter: drop-shadow(rgba(0, 0, 0, 0.2) 0 0 3px);
      text-wrap: wrap;

      .tooltipclose {
        right: 11px;
        top: 9px;
        position: absolute;
        text-decoration: none;
        cursor: pointer;
        font-size: 12px;
      }

      span {
        font-family: Muller-Regular, sans-serif;
        font-size: 14px;
        text-align: center;
        width: 100%;
        display: inherit;
        margin-top: 0;
        letter-spacing: 0.5px;
      }

      b {
        font-family: Muller-Bold, sans-serif;
      }

      .upgradebutton {
        font-size: 11px;
        height: 32px;
        line-height: 32px;
        width: 126px;
        display: block;
        margin: 11px auto 0;
        background-color: #f71851;
        cursor: pointer;
        color: white;
        text-decoration: none;
        border-radius: 3px;
        white-space: nowrap;
        text-align: center;
        padding: 0;
        font-family: Muller-Regular, sans-serif;
        letter-spacing: 0.7px;
        text-transform: uppercase;

        &:hover,
        &:focus,
        &:active {
          color: #fff;
          background-color: #d50a3d;
        }
      }

      // before and after are tooltip bottom arrow
      &:before,
      &:after {
        top: 0;
        left: 0;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block;
      }

      &:before {
        border-width: 0 7.5px 9px 7.5px;
        border-color: transparent transparent #fff transparent;
        top: -9px;
        left: 50px;
      }

      &.limited {
        width: 363px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
        background-color: #f8f8f8;
        border: solid 1px rgba(0, 0, 0, 0);
        padding: 26px 28px 30px;
        right: -380px;
        top: -34px;
        bottom: initial;
        text-align: center;

        span,
        b {
          display: inline;
          font-family: Muller-Regular, sans-serif;
          font-size: 15px;
          line-height: 1.67;
          letter-spacing: 0.8px;
          color: #424f61;
        }

        b {
          font-family: Muller-Bold, sans-serif;
        }

        .upgradebutton {
          margin-top: 26px;
          font-size: 12px;
          height: 40px;
          line-height: 40px;

          &:hover,
          &:focus {
            background-color: #d50a3d;
          }
        }

        &:before {
          top: 32px;
          left: -13px;
          transform: rotate(-90deg);
          border-color: transparent transparent #f8f8f8 transparent;
        }
      }
    }

    .pt-more-wrapper .tab-content {
      overflow: initial !important;
    }
  }
  /* stylelint-enable no-descending-specificity */

  .upload_targets {
    .upgrade-tooltip-wrapper {
      .publishtargets {
        right: -101px;

        &.limited {
          right: -380px;
        }
      }
    }
  }
}
